import {
    GET_INTERVIEW_MONTHLY_STATS,
    GET_INTERVIEW_MONTHLY_STATS_SUCCESS,
    GET_INTERVIEW_MONTHLY_STATS_FAILURE,
    GET_EVENT_LIST,
    GET_EVENT_LIST_SUCCESS,
    GET_EVENT_LIST_FAILURE,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,
    CLEAR_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST_SUCCESS,
    GET_PANELIST_ANALYTICS,
    GET_PANELIST_ANALYTICS_SUCCESS,
    GET_PANELIST_ANALYTICS_FAILURE,
    GET_NOTIFICATION_SETTINGS,
    GET_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_SETTINGS_FAILURE,
    ADD_DELETE_NOTIFICATION_SETTINGS,
    ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS,
    ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE,
    GET_PANELIST_INTERVIEW_STATS,
    GET_PANELIST_INTERVIEW_STATS_SUCCESS,
    GET_PANELIST_INTERVIEW_STATS_FAILURE,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE,
    GET_DASHBOARD_POSITION_LIST,
    GET_DASHBOARD_POSITION_LIST_SUCCESS,
    GET_DASHBOARD_POSITION_LIST_FAILURE,
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    interviewMonthlyStatsData: null,
    subUserInterviewMonthlyStatsData: null,
    eventList: null,
    notificationList: null,
    panelistAnalytics: null,
    notificationSettings: null,
    panelistInterviewStats: null,
    dashboardPositionList: null,
    banners: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_DELETE_NOTIFICATION_SETTINGS:
            return { ...state, loading: true };
        case ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS:
            return { ...state, loading: false };
        case ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE:
            return { ...state, loading: false };
        case GET_NOTIFICATION_SETTINGS:
            return { ...state, loading: true };
        case GET_NOTIFICATION_SETTINGS_SUCCESS:
            return { ...state, loading: false, notificationSettings: action.payload };
        case GET_NOTIFICATION_SETTINGS_FAILURE:
            return { ...state, loading: false };
        case GET_PANELIST_INTERVIEW_STATS:
            return { ...state, loading4: true };
        case GET_PANELIST_INTERVIEW_STATS_SUCCESS:
            return {
                ...state,
                loading4: false,
                panelistInterviewStats: action.payload,
            };
        case GET_PANELIST_INTERVIEW_STATS_FAILURE:
            return { ...state, loading4: false };
        case GET_PANELIST_ANALYTICS:
            return { ...state, loading3: false };
        case GET_PANELIST_ANALYTICS_SUCCESS:
            return {
                ...state,
                loading3: false,
                panelistAnalytics: action.payload,
            };
        case GET_PANELIST_ANALYTICS_FAILURE:
            return { ...state, loading3: false };
        case GET_INTERVIEW_MONTHLY_STATS:
            return { ...state, loading1: true, interviewMonthlyStatsData: null };
        case GET_INTERVIEW_MONTHLY_STATS_SUCCESS:
            return {
                ...state,
                loading1: false,
                interviewMonthlyStatsData: action.payload,
            };
        case GET_INTERVIEW_MONTHLY_STATS_FAILURE:
            return { ...state, loading1: false, interviewMonthlyStatsData: null };

        case GET_SUBUSER_INTERVIEW_MONTHLY_STATS:
            return { ...state, loading2: true };
        case GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS:
            return {
                ...state,
                loading2: false,
                subUserInterviewMonthlyStatsData: action.payload,
            };
        case GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE:
            return { ...state, loading2: false };

        case GET_EVENT_LIST:
            return { ...state, loading: true };
        case GET_EVENT_LIST_SUCCESS:
            return { ...state, loading: false, eventList: action.payload };
        case GET_EVENT_LIST_FAILURE:
            return { ...state, loading: false };

        case GET_NOTIFICATION_LIST:
            return { ...state, loading: false };
        case GET_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false, notificationList: action.payload };
        case GET_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case READ_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case READ_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false };
        case READ_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case CLEAR_NOTIFICATION_LIST:
            return { ...state, loading: true };
        case CLEAR_NOTIFICATION_LIST_SUCCESS:
            return { ...state, loading: false };
        case CLEAR_NOTIFICATION_LIST_FAILURE:
            return { ...state, loading: false };
        case GET_DASHBOARD_POSITION_LIST:
            return { ...state, loading: true };
        case GET_DASHBOARD_POSITION_LIST_SUCCESS:
            return {
                ...state,
                dashboardPositionList: action?.payload,
                loading: false,
            };
        case GET_DASHBOARD_POSITION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case GET_BANNERS:
            return { ...state, loading: true };
        case GET_BANNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                banners: action.payload,
            };
        case GET_BANNERS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
