import { lazy } from 'react';
import ProductDashboard from '../container/ProductDashboard/Product';
import { localStorageDecrypt, localStorageJsonParseDecrypt } from '../utils/helper.jsx';

const PlanPricing = lazy(() => import('../container/ProductDashboard/PlanPricing'));
const Position = lazy(() => import('../container/Position/Position'));
const CreatePanelist = lazy(() => import('../container/Position/CreatePanelist'));
const PostApplicant = lazy(() => import('../container/Interview/PostApplicant'));
const PostInterview = lazy(() => import('../container/Interview/PostInterview'));
const AddOrEditSubUser = lazy(() => import('../container/RolesAndSubUser/AddOrEditSubUser'));
const Support = lazy(() => import('../container/Support'));
const MyProfile = lazy(() => import('../container/MyProfile'));
const AddOrEditRoles = lazy(() => import('../container/RolesAndSubUser/AddOrEditRoles'));
const RolesAndSubUsersList = lazy(() => import('../container/RolesAndSubUser/RolesAndSubUsersList'));
const PaymentInformation = lazy(() => import('../container/ProductDashboard/PaymentInformation'));
const PaymentSuccess = lazy(() => import('../container/ProductDashboard/PaymentSuccess'));
const Notification = lazy(() => import('../container/Notifications'));
const Settings = lazy(() => import('../container/Settings'));
const BulkUploadPage = lazy(() => import('../container/Position/BulkUploadPage'));
const AccountsBilling = lazy(() => import('../container/AccountsBilling'));
const FeedbackReport = lazy(() => import('../component/MyInterviewsComponents/FeedbackReport'));
const SubscriptionExpired = lazy(() => import('../container/SubscriptionExpired'));
const ContactEnquiries = lazy(() => import('../container/ContactEnquiries'));
const DeepLinkPage = lazy(() => import('../container/DeepLinkPage.jsx'));
const MailReport = lazy(() => import('../component/CommonComponent/MailReports'));
const MyInterviews = lazy(() => import('../container/Interview/MyInterviews'));
const Dashboard = lazy(() => import('../container/dashboard'));
const MyPositions = lazy(() => import('../container/Position/MyPositions'));
const Whiteboard = lazy(() => import('../container/Whiteboard'));
const Applicants = lazy(() => import('../container/Applicants/Lists'));
const InterviewInvite = lazy(() => import('../container/Interview/InterviewInvite'));
const currentProduct =
    localStorageDecrypt('productId') && localStorageJsonParseDecrypt('productId')?.productSubDomain?.toLowerCase();

const RoutesFile = [
    {
        path: '/edit-applicant',
        exact: true,
        name: 'PostApplicant',
        component: PostApplicant,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/post-interview',
        exact: true,
        name: 'PostInterview',
        component: PostInterview,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/interview-invite',
        exact: true,
        name: 'InterviewInvite',
        component: InterviewInvite,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/applicants',
        exact: true,
        name: 'Applicants',
        component: Applicants,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/whiteboard/:roomId',
        exact: true,
        name: 'Whiteboard',
        component: Whiteboard,
        private: false,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: false,
    },
    {
        path: '/deeplink/:userId/:deepLinkId',
        exact: true,
        name: 'DeepLinkPage',
        component: DeepLinkPage,
        private: false,
        mainResource: currentProduct,
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: false,
    },
    {
        path: '/dashboard',
        exact: true,
        name: 'Dashboard',
        component: Dashboard,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/position',
        exact: true,
        name: 'Position',
        component: Position,
        private: true,
        mainResource: currentProduct,
        resource: 'positions',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/create-panelist',
        exact: true,
        name: 'CreatePanelist',
        component: CreatePanelist,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/add-applicant',
        exact: true,
        name: 'PostApplicant',
        component: PostApplicant,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/my-interviews',
        exact: true,
        name: 'MyInterviews',
        component: MyInterviews,
        private: true,
        mainResource: currentProduct,
        resource: 'interviews',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/my-positions',
        exact: true,
        name: 'MyPositions',
        component: MyPositions,
        private: true,
        mainResource: currentProduct,
        resource: 'positions',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/my-profile',
        exact: true,
        name: 'My Profile',
        component: MyProfile,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/accounts-billing',
        exact: true,
        name: 'AccountsBilling',
        component: AccountsBilling,
        private: true,
        mainResource: 'commonPermission',
        resource: 'accountsAndBilling',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/settings',
        exact: true,
        name: 'Settings',
        component: Settings,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/subuser',
        exact: true,
        name: 'AddOrEditSubUser',
        component: AddOrEditSubUser,
        private: true,
        mainResource: 'commonPermission',
        resource: 'rolesAndSubusers',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/support',
        exact: true,
        name: 'Support',
        component: Support,
        private: true,
        mainResource: 'commonPermission',
        resource: 'support',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/contact-enquiries',
        exact: true,
        name: 'ContactEnquiries',
        component: ContactEnquiries,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/roles',
        exact: true,
        name: 'AddOrEdit Roles',
        component: AddOrEditRoles,
        private: true,
        mainResource: 'commonPermission',
        resource: 'rolesAndSubusers',
        action: 'create',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/roles-subuser-list',
        exact: true,
        name: 'RolesAndSubUsersList',
        component: RolesAndSubUsersList,
        private: true,
        mainResource: 'commonPermission',
        resource: 'rolesAndSubusers',
        action: 'view',
        permission: true,
        loginRequired: true,
    },
    {
        path: '/product',
        exact: true,
        name: 'ProductDashboard',
        component: ProductDashboard,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/plan-pricing',
        exact: true,
        name: 'PlanPricing',
        component: PlanPricing,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/payment-information',
        exact: true,
        name: 'PaymentInformation',
        component: PaymentInformation,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/payment-success',
        exact: true,
        name: 'PaymentSuccess',
        component: PaymentSuccess,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/notification',
        exact: true,
        name: 'Notification',
        component: Notification,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/bulk-upload',
        exact: true,
        name: 'BulkUpload',
        component: BulkUploadPage,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/vip-create-panelist',
        exact: true,
        name: 'CreatePanelist',
        component: CreatePanelist,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/feedback/:panelistId/:interviewId',
        exact: true,
        name: 'FeedbackReport',
        component: FeedbackReport,
        private: false,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: false,
    },
    {
        path: '/subscription-expired',
        exact: true,
        name: 'SubcriptionExpired',
        component: SubscriptionExpired,
        private: true,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: true,
    },
    {
        path: '/conversion-report/:user',
        exact: true,
        name: 'MailReport',
        component: MailReport,
        mainResource: '',
        resource: '',
        action: 'view',
        permission: false,
        loginRequired: false,
    },
];

export default RoutesFile;
