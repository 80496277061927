import React, { memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import step1 from '../../assets/images/step-1.svg';
import step11 from '../../assets/images/step-1.1.svg';
import step2 from '../../assets/images/step-2.svg';
import step22 from '../../assets/images/step-2.2.svg';
import step3 from '../../assets/images/addApplicant.svg';
import step33 from '../../assets/images/addApplicantDisabled.svg';

const QuickSteps = memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="quick-steps f-bol">
            {location?.pathname === '/position' ? (
                <img
                    src={step1}
                    alt="Create Position"
                    className={'step-img step-1'}
                    onClick={() => {
                        navigate(location.pathname, { replace: true });
                        window.location.reload();
                    }}
                />
            ) : (
                <img
                    src={step11}
                    alt="Create Position"
                    className={'step-img step-1'}
                    onClick={() => {
                        navigate('/position');
                    }}
                />
            )}
            {location?.pathname === '/create-panelist' ? (
                <img
                    src={step2}
                    alt="Create Panelist"
                    className={'step-img step-2'}
                    onClick={() => {
                        navigate(location.pathname, { replace: true });
                        window.location.reload();
                    }}
                />
            ) : (
                <img
                    src={step22}
                    className={'step-img step-2'}
                    alt="Create Panelist"
                    onClick={() => {
                        navigate('/create-panelist');
                    }}
                />
            )}
            {location?.pathname === '/add-applicant' ? (
                <img
                    src={step3}
                    alt="Post Interview"
                    className={'step-img step-3'}
                    onClick={() => {
                        navigate(location.pathname, { replace: true });
                        window.location.reload();
                    }}
                />
            ) : (
                <img
                    src={step33}
                    alt="Post Interview"
                    className={'step-img step-3'}
                    onClick={() => {
                        navigate('/add-applicant', {
                            state: { isBulkUpload: false, addApplicant: true, showToggle: true },
                        });
                    }}
                />
            )}
        </div>
    );
});

export default QuickSteps;
