import React, { useState, useEffect } from 'react';
import { localStorageDecrypt, localStorageJsonParseDecrypt, hasPageAccess, getAccessToken } from '../utils/helper';
import { useNavigate, useLocation } from 'react-router-dom';
import { getBanners, getProductList, getUserPermission, loginUser } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import greenTick from '../assets/images/product-round-tick.svg';
import { useRef } from 'react';
import { COMMON_PAGE_LIST } from '../utils/constant';
import Header from '../component/Layout/Header';
import Sidebar from '../component/Layout/Sidebar';
import Loader from '../component/CommonComponent/Loader';
import RestrictedAccess from './RestrictedAccess';
import ProductDashboardPopup from '../container/ProductDashboard/Product';
const MainPage = ({ Component, pvt, resource, mainResource, permission, action }) => {
    const token = getAccessToken;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const wrapperRef = useRef();
    const [userData, setUserData] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [activeSidebar, setActiveSidebar] = useState(false);
    const productLoading = useSelector((state) => state.Product.loading);
    const productDetails = useSelector((state) => state.Product.productData);
    const loading = useSelector((state) => state?.RolesAndSubUser?.pLoading);
    const userPermissionList = useSelector((state) => state?.RolesAndSubUser?.userPermissionList);
    const banners = useSelector((state) => state?.Dashboard?.banners);
    const [popup, setPopup] = useState(false);
    const [mobSidebar, setMobSidebar] = useState(false);
    const [bannerLength, setBannerLength] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const headerHeight = bannerLength ? bannerLength * 25 + 60 : 60;
    const headerStyle = windowWidth < 1400 && windowWidth > 992 ? { height: `${headerHeight}px` } : {};
    const contentStyle = windowWidth < 1400 ? { height: `calc(100vh - ${headerHeight}px)` } : {};

    useEffect(() => {
        if (banners && banners?.length > 0) {
            setBannerLength(banners?.length);
        }
    }, [banners]);

    useEffect(() => {
        if (location?.pathname === '/product') {
            const user = localStorageJsonParseDecrypt('userData') ?? userData;
            const userType = user?.userType ?? '';
            if (!userType) {
                dispatch(
                    loginUser({
                        callback: (data) => {
                            if (data?.user) {
                                setUserData(data?.user);
                            }
                            if (data?.businessDetails) {
                                setCompanyData(data?.businessDetails);
                            }
                        },
                    })
                );
            }
        }
        dispatch(getBanners());
        dispatch(getProductList());
        dispatch(getUserPermission());
    }, []);

    useEffect(() => {
        if (location?.pathname === '/product') {
            setActiveSidebar(true);
        } else {
            setActiveSidebar(false);
        }
    }, [location?.pathname]);

    useEffect(() => {
        const pageList = [
            '/dashboard',
            '/my-interviews',
            '/create-panelist',
            '/my-positions',
            '/position',
            '/add-applicant',
        ];
        const url = window.location.href;
        if (
            (url.includes('code') || url.includes('state') || url.includes('access_token')) &&
            location?.pathname === '/my-interviews'
        ) {
        } else {
            if (pageList.includes(location?.pathname)) {
                if (!localStorageDecrypt('productId')) {
                    navigate('/product');
                } else if (
                    !['InCFeed', 'InCVid'].includes(localStorageJsonParseDecrypt('productId')?.productSubDomain)
                ) {
                    navigate('/product');
                }
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleClickInsideWrapper = (event) => {
            if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
                dispatch(getUserPermission());
            }
        };
        document.addEventListener('click', handleClickInsideWrapper);
        return () => {
            document.removeEventListener('click', handleClickInsideWrapper);
        };
    }, []);

    useEffect(() => {
        //this code is check whether the sub-user has access to the logged in product or not
        const userType = localStorageJsonParseDecrypt('userData')?.userType ?? '';
        const currentProduct = localStorageJsonParseDecrypt('productId')?.productName ?? '';
        const currentProductPermissions = userPermissionList?.find(
            (data) => data?.name?.toLowerCase() === currentProduct?.toLowerCase()
        );
        if (userType && currentProductPermissions) {
            const shouldRedirect =
                userType !== 'employer' &&
                !currentProductPermissions?.status &&
                !COMMON_PAGE_LIST?.includes(location?.pathname);
            if (shouldRedirect) {
                navigate('/product');
            }
        }
    }, [userPermissionList]);

    const componentDelivery = () => {
        return (
            <Component
                activeSidebar={activeSidebar}
                setActiveSidebar={setActiveSidebar}
                userPermissionList={userPermissionList}
                resource={resource}
                mainResource={mainResource}
                action={action}
                productLoading={productLoading}
                productDetails={productDetails}
                companyData={companyData ?? localStorageJsonParseDecrypt('companyData')}
                userData={userData ?? localStorageJsonParseDecrypt('userData')}
            />
        );
    };

    return pvt ? (
        <div className="main-container" ref={wrapperRef}>
            <div style={headerStyle} className="header-container d-flex flex-column">
                {token && (
                    <Header
                        mobSidebar={mobSidebar}
                        setMobSidebar={setMobSidebar}
                        userPermissionList={userPermissionList}
                        resource={resource}
                        mainResource={mainResource}
                        action={action}
                        productLoading={productLoading}
                        productDetails={productDetails}
                        companyData={companyData ?? localStorageJsonParseDecrypt('companyData')}
                        userData={userData ?? localStorageJsonParseDecrypt('userData')}
                        banners={banners}
                    />
                )}
            </div>
            <div style={contentStyle} className="content-container d-flex justify-content-start position-relative">
                {token && (
                    <Sidebar
                        activeSidebar={activeSidebar}
                        setActiveSidebar={setActiveSidebar}
                        mobSidebar={mobSidebar}
                        setMobSidebar={setMobSidebar}
                        productLoading={productLoading}
                        productDetails={productDetails}
                        companyData={companyData ?? localStorageJsonParseDecrypt('companyData')}
                        userData={userData ?? localStorageJsonParseDecrypt('userData')}
                        userPermissionList={userPermissionList}
                        resource={resource}
                        mainResource={mainResource}
                        action={action}
                    >
                        {permission ? (
                            loading ? (
                                <Loader />
                            ) : hasPageAccess(userPermissionList, mainResource, resource, action) ? (
                                componentDelivery()
                            ) : userPermissionList ? (
                                <RestrictedAccess />
                            ) : (
                                <Loader />
                            )
                        ) : (
                            componentDelivery()
                        )}
                    </Sidebar>
                )}
            </div>
            {popup && (
                <ProductDashboardPopup
                    img={greenTick}
                    title={'Sent !'}
                    subTitle="Thank you for submitting your product inquiry. Our sales team has received your message and  will get back to you shortly."
                    btnName={'Okay'}
                    btnClass={'ok-btn f-14 f-sem-bol rounded-pill'}
                    onClick={() => setPopup(!popup)}
                    activeSidebar={activeSidebar}
                />
            )}
        </div>
    ) : (
        <Component />
    );
};

export default MainPage;
