import {
    GET_INTERVIEW_MONTHLY_STATS,
    GET_INTERVIEW_MONTHLY_STATS_SUCCESS,
    GET_INTERVIEW_MONTHLY_STATS_FAILURE,
    GET_EVENT_LIST,
    GET_EVENT_LIST_SUCCESS,
    GET_EVENT_LIST_FAILURE,
    GET_NOTIFICATION_LIST,
    GET_NOTIFICATION_LIST_SUCCESS,
    GET_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST,
    READ_NOTIFICATION_LIST_FAILURE,
    READ_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST,
    CLEAR_NOTIFICATION_LIST_SUCCESS,
    CLEAR_NOTIFICATION_LIST_FAILURE,
    GET_PANELIST_ANALYTICS,
    GET_PANELIST_ANALYTICS_SUCCESS,
    GET_PANELIST_ANALYTICS_FAILURE,
    GET_NOTIFICATION_SETTINGS,
    GET_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_SETTINGS_FAILURE,
    ADD_DELETE_NOTIFICATION_SETTINGS,
    ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS,
    ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE,
    GET_PANELIST_INTERVIEW_STATS,
    GET_PANELIST_INTERVIEW_STATS_SUCCESS,
    GET_PANELIST_INTERVIEW_STATS_FAILURE,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS,
    GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE,
    GET_DASHBOARD_POSITION_LIST,
    GET_DASHBOARD_POSITION_LIST_SUCCESS,
    GET_DASHBOARD_POSITION_LIST_FAILURE,
    GET_BANNERS,
    GET_BANNERS_SUCCESS,
    GET_BANNERS_FAILURE,
} from './types';

export const getPanelistAnalytics = (payload) => ({
    type: GET_PANELIST_ANALYTICS,
    payload,
});
export const getPanelistAnalyticsSuccess = (payload) => ({
    type: GET_PANELIST_ANALYTICS_SUCCESS,
    payload,
});
export const getPanelistAnalyticsFailure = () => ({
    type: GET_PANELIST_ANALYTICS_FAILURE,
});

export const getInterviewMonthlyStats = (payload) => ({
    type: GET_INTERVIEW_MONTHLY_STATS,
    payload,
});
export const getInterviewMonthlyStatsSuccess = (payload) => ({
    type: GET_INTERVIEW_MONTHLY_STATS_SUCCESS,
    payload,
});
export const getInterviewMonthlyStatsFailure = () => ({
    type: GET_INTERVIEW_MONTHLY_STATS_FAILURE,
});
export const getSubUserInterviewMonthlyStats = (payload) => ({
    type: GET_SUBUSER_INTERVIEW_MONTHLY_STATS,
    payload,
});
export const getSubUserInterviewMonthlyStatsSuccess = (payload) => ({
    type: GET_SUBUSER_INTERVIEW_MONTHLY_STATS_SUCCESS,
    payload,
});
export const getSubUserInterviewMonthlyStatsFailure = () => ({
    type: GET_SUBUSER_INTERVIEW_MONTHLY_STATS_FAILURE,
});

export const getEventList = (payload) => ({
    type: GET_EVENT_LIST,
    payload,
});
export const getEventListSuccess = (payload) => ({
    type: GET_EVENT_LIST_SUCCESS,
    payload,
});
export const getEventListFailure = () => ({
    type: GET_EVENT_LIST_FAILURE,
});

export const getNotification = (payload) => ({
    type: GET_NOTIFICATION_LIST,
    payload,
});
export const getNotificationSuccess = (payload) => ({
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload,
});
export const getNotificationFailure = () => ({
    type: GET_NOTIFICATION_LIST_FAILURE,
});
export const readNotification = (payload) => ({
    type: READ_NOTIFICATION_LIST,
    payload,
});
export const readNotificationSuccess = () => ({
    type: READ_NOTIFICATION_LIST_SUCCESS,
});
export const readNotificationFailure = () => ({
    type: READ_NOTIFICATION_LIST_FAILURE,
});
export const clearNotification = (payload) => ({
    type: CLEAR_NOTIFICATION_LIST,
    payload,
});

export const clearNotificationSuccess = (payload) => ({
    type: CLEAR_NOTIFICATION_LIST_SUCCESS,
    payload,
});
export const clearNotificationFailure = () => ({
    type: CLEAR_NOTIFICATION_LIST_FAILURE,
});

export const getNotificationSettings = () => ({
    type: GET_NOTIFICATION_SETTINGS,
});

export const getNotificationSettingsSuccess = (payload) => ({
    type: GET_NOTIFICATION_SETTINGS_SUCCESS,
    payload,
});

export const getNotificationSettingsFailure = () => ({
    type: GET_NOTIFICATION_SETTINGS_FAILURE,
});

export const addDeleteNotificationSettings = (payload) => ({
    type: ADD_DELETE_NOTIFICATION_SETTINGS,
    payload,
});

export const addDeleteNotificationSettingsSuccess = () => ({
    type: ADD_DELETE_NOTIFICATION_SETTINGS_SUCCESS,
});

export const addDeleteNotificationSettingsFailure = () => ({
    type: ADD_DELETE_NOTIFICATION_SETTINGS_FAILURE,
});

export const getPanelistInterviewStats = (payload) => ({
    type: GET_PANELIST_INTERVIEW_STATS,
    payload,
});

export const getPanelistInterviewStatsSuccess = (payload) => ({
    type: GET_PANELIST_INTERVIEW_STATS_SUCCESS,
    payload,
});

export const getPanelistInterviewStatsFailure = () => ({
    type: GET_PANELIST_INTERVIEW_STATS_FAILURE,
});

export const getDashboardPositionList = (payload) => ({
    type: GET_DASHBOARD_POSITION_LIST,
    payload,
});

export const getDashboardPositionListSuccess = (payload) => ({
    type: GET_DASHBOARD_POSITION_LIST_SUCCESS,
    payload,
});

export const getDashboardPositionListFailure = () => ({
    type: GET_DASHBOARD_POSITION_LIST_FAILURE,
});

export const getBanners = () => ({
    type: GET_BANNERS,
});
export const getBannersSuccess = (payload) => ({
    type: GET_BANNERS_SUCCESS,
    payload,
});
export const getBannersFailure = () => ({
    type: GET_BANNERS_FAILURE,
});
